import {TableProps} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useTableSearchParams} from "./UseTableSearchParams";
import {GetCategoryDTO} from "../types/category/GetCategoryDTO";
import {useGetCategoriesQuery} from "../store/api/CategoryApi";
import {selectQueryFromParams} from "../utils/ConvertQueryParams";
import {Filter} from "../types/utils/Filter";

type UseGetCategoriesType = () => {
    categories: any
    onChangeTable: TableProps<GetCategoryDTO>["onChange"]
    loading: boolean,
    filter: Filter
    total: number | undefined
    error: any
}

export const useGetCategories: UseGetCategoriesType = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {filter} = useTableSearchParams()
    const {data, isFetching, error} = useGetCategoriesQuery({
        page: filter.page,
        size: filter.size
    })

    const onChangeTable: TableProps<GetCategoryDTO>["onChange"] = (pagination) => {
        navigate({
            search: selectQueryFromParams(pagination, location.search)
        })
    }

    return {
        categories: data?.data?.content,
        onChangeTable,
        loading: isFetching,
        filter,
        total: data?.data?.totalElements,
        error
    }
}