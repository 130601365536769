import {HashRouter, Route, Routes} from "react-router-dom";
import Product from "./features/product/Product";
import Category from "./features/category/Category";
import Login from "./features/auth/Login";
import AuthProvider from "./features/auth/AuthProvider";

function App() {
    return (
        <HashRouter>
            <AuthProvider>
                <Routes>
                    <Route path="/category" index element={<Category/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/product" element={<Product/>}/>
                    <Route path={'*'} element={<Login/>}/>
                </Routes>
            </AuthProvider>
        </HashRouter>
    );
}

export default App;
