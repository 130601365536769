import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {TOKEN} from "../../utils/Constants";

interface PermissionProviderProps {
    children?: React.ReactNode
}

const AuthProvider: React.FC<PermissionProviderProps> = ({children}) => {
    const navigate = useNavigate()
    const token = localStorage.getItem(TOKEN)

    useEffect(() => {
        if (!token) {
            navigate('/login')
        }
    }, [])

    return (
        <>
            {children}
        </>
    );
}

export default AuthProvider;