import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {Response} from "../../types/response/Response";
import {LoginDTO} from "../../types/auth/LoginDTO";
import {fetchBaseQuery} from "@reduxjs/toolkit/query";

export const authApi = createApi({
    reducerPath: 'authUrl',
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_BASE_URL}),
    tagTypes: ['authUrl'],
    endpoints: builder => ({
        auth: builder.mutation<Response<string>, Partial<LoginDTO>>({
            query: (dto) => ({
                url: `/auth`,
                method: 'POST',
                body: dto
            }),
            invalidatesTags: ['authUrl']
        })
    })
})

export const {
    useAuthMutation,
} = authApi