import {notification} from "antd";
import {ERROR, NOTIFICATION_DURATION} from "./Constants";

export const showSuccess = (message: string) => {
    notification.success({
        message: message,
        duration: NOTIFICATION_DURATION
    })
}

export const showError = (description: string) => {
    notification.error({
        message: ERROR,
        description: description,
        duration: NOTIFICATION_DURATION
    })
}