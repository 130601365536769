import {TableProps} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useTableSearchParams} from "./UseTableSearchParams";
import {selectQueryFromParams} from "../utils/ConvertQueryParams";
import {Filter} from "../types/utils/Filter";
import {useGetProductsQuery} from "../store/api/ProductApi";
import {GetProductDTO} from "../types/product/GetProductDTO";

type UseGetProductsType = () => {
    products: any
    onChangeTable: TableProps<GetProductDTO>["onChange"]
    loading: boolean,
    filter: Filter
    total: number | undefined
    error: any
}

export const useGetProducts: UseGetProductsType = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {filter} = useTableSearchParams()
    const {data, isFetching, error} = useGetProductsQuery({
        page: filter.page,
        size: filter.size
    })

    const onChangeTable: TableProps<GetProductDTO>["onChange"] = (pagination) => {
        navigate({
            search: selectQueryFromParams(pagination, location.search)
        })
    }

    return {
        products: data?.data?.content,
        onChangeTable,
        loading: isFetching,
        filter,
        total: data?.data?.totalElements,
        error
    }
}