import {Form, Input, message, Select, Upload, UploadFile} from "antd";
import React from "react";
import {UploadOutlined} from "@ant-design/icons";
import {LanguageDTO} from "../../types/utils/LanguageDTO";
import {GetProductDTO} from "../../types/product/GetProductDTO";
import {CreateProductDTO} from "../../types/product/CreateProductDTO";
import {useGetCategoriesQuery} from "../../store/api/CategoryApi";
import {useTableSearchParams} from "../../hooks/UseTableSearchParams";
import {BEARER, TOKEN} from "../../utils/Constants";

const {Dragger} = Upload

interface ProductCreateFormProps {
    product: GetProductDTO | undefined
    onFinish: (data: CreateProductDTO) => void
}

const ProductCreateForm: React.FC<ProductCreateFormProps> = ({product, onFinish}) => {
    const [form] = Form.useForm()
    const {filter} = useTableSearchParams()
    const {data: categories} = useGetCategoriesQuery({
        page: filter.page,
        size: filter.size
    })
    const token = localStorage.getItem(TOKEN)

    const onFinishHandler = async (values: any) => {
        const languageDTO: LanguageDTO = {
            uz: values.name_uz,
            ru: values.name_ru
        }
        const dto: CreateProductDTO = {
            name: values.name_uz === product?.name.uz && values.name_ru === product?.name.ru ? undefined : languageDTO,
            category_id: values.category_id === product?.category.id ? undefined : values.category_id,
            price: values.price === product?.price ? undefined : values.price,
            loyalty_count: values.loyalty_count === product?.loyalty_count ? undefined : values.loyalty_count,
            picture_url: values.picture_url === product?.picture_url ? undefined : `/attachments/${values.picture_url.file.response.data}`
        }
        onFinish(dto)
    }

    const beforeUpload = (file: UploadFile) => {
        const isLt2M = file.size! < 2e6
        if (!isLt2M) {
            message.error("Размер изображения должен быть меньше 2 МБ")
        }
        return isLt2M || Upload.LIST_IGNORE;
    }

    return (
        <Form form={form} onFinish={onFinishHandler} id={'product-create-form'} layout="vertical"
              initialValues={{
                  name_uz: product?.name?.uz,
                  name_ru: product?.name?.ru,
                  category_id: product?.category.id,
                  price: product?.price,
                  loyalty_count: product?.loyalty_count,
                  picture_url: product?.picture_url
              }}>
            <Form.Item
                name="name_uz"
                label="Название на узбекском"
                rules={[{required: true, whitespace: true, message: "Введите название"}]}
            >
                <Input placeholder={'Введите название'}/>
            </Form.Item>
            <Form.Item
                name="name_ru"
                label="Название на русском"
                rules={[{required: true, whitespace: true, message: "Введите название"}]}
            >
                <Input placeholder={'Введите название'}/>
            </Form.Item>
            <Form.Item
                name="category_id"
                label="Категория"
                rules={[{required: true, message: "Выберите категорию"}]}
            >
                <Select
                    placeholder={'Выберите категорию'}
                    options={categories?.data?.content.map(item => ({
                        value: item.id,
                        label: item.name.ru
                    }))}
                />
            </Form.Item>
            <Form.Item
                name="price"
                label="Цена"
                rules={[{
                    required: true,
                    message: "Введите цену"
                }]}
            >
                <Input placeholder={'Введите цену'}/>
            </Form.Item>
            <Form.Item
                name="loyalty_count"
                label="Количество лояльности"
            >
                <Input placeholder={'Введите количество лояльности'}/>
            </Form.Item>
            <Form.Item
                name="picture_url"
                label={<div>Фото <span style={{fontSize: '10px'}}>(Разрешение файлов изображений: JPG, PNG)</span>
                </div>}
                rules={[{required: true, message: "Загружите фото"}]}
            >
                <Dragger accept={"image/png, image/jpeg"} listType={'picture'} maxCount={1}
                         action={`${process.env.REACT_APP_BASE_URL}/attachments/upload`}
                         headers={{Authorization: `${BEARER} ${token}`}}
                         defaultFileList={product && [{
                             uid: `${product.id}`,
                             name: `${product.picture_url.substring(product.picture_url.lastIndexOf('/') + 1)}`,
                             url: `${process.env.REACT_APP_BASE_URL}${product.picture_url}`
                         }]} beforeUpload={beforeUpload}
                         showUploadList={{showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false}}>
                    <UploadOutlined/>
                    <p className="ant-upload-text">Нажмите или перетащите фото для загрузки</p>
                </Dragger>
            </Form.Item>
        </Form>
    )
}

export default ProductCreateForm