import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {useDispatch as useStoreDispatch} from "react-redux";
import {categoryApi} from "./api/CategoryApi";
import {productApi} from "./api/ProductApi";
import {authApi} from "./api/AuthApi";

// Reducers
export const reducer = combineReducers({
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [authApi.reducerPath]: authApi.reducer
})

export interface AppThunkProps {
    dispatch: AppDispatch;
    state: StoreState;
    extra?: unknown;
    rejectValue?: unknown;
}

export type StoreState = ReturnType<typeof reducer>
export type AppDispatch = typeof store.dispatch

export const useDispatch = () => useStoreDispatch<AppDispatch>()

export const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({immutableCheck: false})
        .concat(categoryApi.middleware)
        .concat(productApi.middleware)
        .concat(authApi.middleware)
})