import type {ColumnsType} from "antd/es/table"
import {GetCategoryDTO} from "../../types/category/GetCategoryDTO";
import {Image} from 'antd';
import CategoryAction from "./CategoryAction";

type UseColumnsType = () => ColumnsType<GetCategoryDTO>

export const useColumns: UseColumnsType = () => {

    const columns: ColumnsType<GetCategoryDTO> = [
        {
            title: "ИД",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Название категории на узбекском",
            dataIndex: "name_uz",
            key: "name_uz",
            render: (_, {name}) => name.uz
        },
        {
            title: "Название категории на русском",
            dataIndex: "name_ru",
            key: "name_ru",
            render: (_, {name}) => name.ru
        },
        {
            title: "Фото",
            dataIndex: "picture_url",
            key: "picture_url",
            render: (_, {picture_url}) => <>
                <Image width={30} src={`${process.env.REACT_APP_BASE_URL}${picture_url}`}></Image>
            </>
        },
        {
            title: "Действие",
            dataIndex: "action",
            key: "action",
            align: "center",
            render: (_, record) => <CategoryAction category={record}/>
        }
    ]

    return columns
}