import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {Divider, Modal} from "antd";
import React, {useEffect, useState} from "react";
import ProductCreateForm from "./ProductCreateForm";
import {GetProductDTO} from "../../types/product/GetProductDTO";
import {CreateProductDTO} from "../../types/product/CreateProductDTO";
import {useDeleteProductMutation, useUpdateProductMutation} from "../../store/api/ProductApi";
import {showError, showSuccess} from "../../utils/Extensions";
import {PRODUCT_SUCCESSFULLY_DELETED, PRODUCT_SUCCESSFULLY_UPDATED} from "../../utils/Constants";

interface ProductActionButtonProps {
    product: GetProductDTO
}

const ProductAction: React.FC<ProductActionButtonProps> = ({product}) => {
    const [visibleUpdate, setVisibleUpdate] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [updateProduct, {
        isSuccess: isSuccessUpdate,
        isError: isErrorUpdate,
        error: errorUpdate
    }] = useUpdateProductMutation()
    const [deleteProduct, {
        isSuccess: isSuccessDelete,
        isError: isErrorDelete,
        error: errorDelete
    }] = useDeleteProductMutation()

    useEffect(() => {
        if (isSuccessUpdate) showSuccess(PRODUCT_SUCCESSFULLY_UPDATED)
        if (isErrorUpdate) showError((errorUpdate as any)?.data?.message)
        if (isSuccessDelete) showSuccess(PRODUCT_SUCCESSFULLY_DELETED)
        if (isErrorDelete) showError((errorDelete as any)?.data?.message)
    }, [isSuccessUpdate, isErrorUpdate, isSuccessDelete, isErrorDelete, errorUpdate, errorDelete])

    const onClickHandler = async (key: string) => {
        switch (key) {
            case 'update' : {
                setVisibleUpdate(true)
                break
            }
            case 'delete': {
                setVisibleDelete(true)
                break
            }
        }
    }

    const onCloseUpdateHandler = () => setVisibleUpdate(false)

    const onFinishUpdateHandler = async (id: number, dto: CreateProductDTO) => {
        setLoadingUpdate(true)
        updateProduct({id, dto})
        setVisibleUpdate(false)
        setLoadingUpdate(false)
    }

    const onCloseDeleteHandler = () => setVisibleDelete(false)

    const onFinishDeleteHandler = async (id: number) => {
        setLoadingDelete(true)
        deleteProduct(id)
        setVisibleDelete(false)
        setLoadingDelete(false)
    }

    return (
        <>
            <EditOutlined style={{color: 'yellowgreen', margin: '0 4px', fontSize: '120%'}}
                          onClick={() => onClickHandler("update")}/>
            <DeleteOutlined style={{color: 'red', margin: '0 4px', fontSize: '120%'}}
                            onClick={() => onClickHandler("delete")}/>
            <Modal
                title="Изменить продукт"
                open={visibleUpdate}
                onCancel={onCloseUpdateHandler}
                okText="Изменить"
                destroyOnClose
                okButtonProps={{
                    disabled: loadingUpdate,
                    form: "product-create-form",
                    htmlType: "submit"
                }}
            >
                <Divider/>
                <ProductCreateForm product={product} onFinish={(data) => onFinishUpdateHandler(product.id, data)}/>
            </Modal>
            <Modal
                title="Вы действительно хотите удалить?"
                open={visibleDelete}
                onCancel={onCloseDeleteHandler}
                okText="Удалить"
                destroyOnClose
                onOk={() => onFinishDeleteHandler(product.id)}
                okButtonProps={{
                    disabled: loadingDelete
                }}
            />
        </>
    )
}

export default ProductAction;