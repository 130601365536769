import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {GetCategoryDTO} from "../../types/category/GetCategoryDTO";
import {ResponsePageable} from "../../types/response/ResponsePageable";
import {Response} from "../../types/response/Response";
import {apiQuery} from "../../utils/AppConfig";
import {CreateCategoryDTO} from "../../types/category/CreateCategoryDTO";

export const categoryApi = createApi({
    reducerPath: 'categoryUrl',
    baseQuery: apiQuery,
    tagTypes: ['categoryUrl'],
    endpoints: builder => ({
        getCategories: builder.query<Response<ResponsePageable<GetCategoryDTO>>, {
            page: number
            size: number
        }>({
            query: (dto) => ({
                url: `/categories?page=${dto.page}&size=${dto.size}`,
                method: 'GET'
            }),
            providesTags: ['categoryUrl']
        }),
        createCategory: builder.mutation<void, Partial<CreateCategoryDTO>>({
            query: (dto) => ({
                url: '/categories',
                method: 'POST',
                body: dto
            }),
            invalidatesTags: ['categoryUrl']
        }),
        updateCategory: builder.mutation<void, Partial<{ id: number, dto: CreateCategoryDTO }>>({
            query: ({id, dto}) => ({
                url: `/categories/${id}`,
                method: 'PATCH',
                body: dto
            }),
            invalidatesTags: ['categoryUrl']
        }),
        deleteCategory: builder.mutation<void, number>({
            query: (id) => ({
                url: `/categories/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['categoryUrl']
        }),
    })
})

export const {
    useGetCategoriesQuery,
    useCreateCategoryMutation,
    useUpdateCategoryMutation,
    useDeleteCategoryMutation
} = categoryApi