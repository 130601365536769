import {useLocation, useNavigate, useSearchParams} from "react-router-dom"
import {useCallback, useEffect, useMemo, useState} from "react"
import {Filter} from "../types/utils/Filter";
import {selectParamsFromQuery} from "../utils/ConvertQueryParams";

type UseTableSearchParamsType = () => {
    searchParamsString: string
    searchParams: URLSearchParams
    filter: Filter
    clearFilterByKey: (data: { name: keyof Filter, val?: Filter[keyof Filter] }) => void,
}

export const useTableSearchParams: UseTableSearchParamsType = () => {
    const [searchParams] = useSearchParams()
    const filterFromQuery = selectParamsFromQuery(searchParams)
    const searchParamsString = useMemo(() => searchParams.toString(), [searchParams])
    const navigate = useNavigate()
    const location = useLocation()
    const [filter, setFilter] = useState<Filter>(filterFromQuery)

    const clearFilterByKey = async (action: { name: keyof Filter, val?: Filter[keyof Filter] }) => {
        const {name} = action
        searchParams.delete(name)
        navigate({search: searchParams.toString()})
    }

    const queryToObject = useCallback((locationSearch: string) => {
        const query = new URLSearchParams(locationSearch)
        const params = selectParamsFromQuery(query)
        setFilter(params)
    }, [])

    useEffect(() => {
        queryToObject(location?.search)
    }, [location, queryToObject])

    return {
        clearFilterByKey,
        searchParamsString,
        searchParams,
        filter,
    }
}
