import {Form, Input, message, Upload, UploadFile} from "antd";
import React from "react";
import {UploadOutlined} from "@ant-design/icons";
import {CreateCategoryDTO} from "../../types/category/CreateCategoryDTO";
import {GetCategoryDTO} from "../../types/category/GetCategoryDTO";
import {LanguageDTO} from "../../types/utils/LanguageDTO";
import {BEARER, TOKEN} from "../../utils/Constants";

const {Dragger} = Upload

interface CategoryCreateFormProps {
    category: GetCategoryDTO | undefined
    onFinish: (data: CreateCategoryDTO) => void
}

const CategoryCreateForm: React.FC<CategoryCreateFormProps> = ({category, onFinish}) => {
    const [form] = Form.useForm()
    const token = localStorage.getItem(TOKEN)

    const onFinishHandler = async (values: any) => {
        const languageDTO: LanguageDTO = {
            uz: values.name_uz,
            ru: values.name_ru
        }
        const dto: CreateCategoryDTO = {
            name: values.name_uz === category?.name.uz && values.name_ru === category?.name.ru ? undefined : languageDTO,
            picture_url: values.picture_url === category?.picture_url ? undefined : `/attachments/${values.picture_url.file.response.data}`
        }
        onFinish(dto)
    }

    const beforeUpload = (file: UploadFile) => {
        const isLt2M = file.size! < 2e6
        if (!isLt2M) {
            message.error("Размер изображения должен быть меньше 2 МБ")
        }
        return isLt2M || Upload.LIST_IGNORE;
    }

    return (
        <Form form={form} onFinish={onFinishHandler} id={'category-create-form'} layout="vertical"
              initialValues={{
                  name_uz: category?.name?.uz,
                  name_ru: category?.name?.ru,
                  picture_url: category?.picture_url
              }}>
            <Form.Item
                name="name_uz"
                label="Название на узбекском"
                rules={[{required: true, whitespace: true, message: "Введите название"}]}
            >
                <Input placeholder={'Введите название'}/>
            </Form.Item>
            <Form.Item
                name="name_ru"
                label="Название на русском"
                rules={[{required: true, whitespace: true, message: "Введите название"}]}
            >
                <Input placeholder={'Введите название'}/>
            </Form.Item>
            <Form.Item
                name="picture_url"
                label={<div>Фото <span style={{fontSize: '10px'}}>(Разрешение файлов изображений: JPG, PNG)</span>
                </div>}
                rules={[{required: true, message: "Загружите фото"}]}
            >
                <Dragger accept={"image/png, image/jpeg"} listType={'picture'} maxCount={1}
                         action={`${process.env.REACT_APP_BASE_URL}/attachments/upload`}
                         headers={{Authorization: `${BEARER} ${token}`}}
                         defaultFileList={category && [{
                             uid: `${category.id}`,
                             name: `${category.picture_url.substring(category.picture_url.lastIndexOf('/') + 1)}`,
                             url: `${process.env.REACT_APP_BASE_URL}${category.picture_url}`
                         }]} beforeUpload={beforeUpload}
                         showUploadList={{showPreviewIcon: false, showRemoveIcon: false, showDownloadIcon: false}}>
                    <UploadOutlined/>
                    <p className="ant-upload-text">Нажмите или перетащите фото для загрузки</p>
                </Dragger>
            </Form.Item>
        </Form>
    )
}

export default CategoryCreateForm