import React, {useEffect, useState} from 'react';
import {Button, Divider, Modal, Table} from 'antd';
import {useGetCategories} from "../../hooks/UseGetCategories";
import {useColumns} from "./UseColumns";
import {CreateCategoryDTO} from "../../types/category/CreateCategoryDTO";
import {useCreateCategoryMutation} from "../../store/api/CategoryApi";
import CategoryCreateForm from "./CategoryCreateForm";
import Layout from "../../layouts/Layout";
import {useNavigate} from "react-router-dom";
import {CATEGORY_SUCCESSFULLY_ADDED} from "../../utils/Constants";
import {showError, showSuccess} from "../../utils/Extensions";

const Category: React.FC = () => {

    const {categories, onChangeTable, loading, filter, total, error} = useGetCategories()
    const columns = useColumns()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [createCategory, {
        isSuccess: isSuccessCreate,
        isError: isErrorCreate,
        error: errorCreate
    }] = useCreateCategoryMutation()

    useEffect(() => {
        setTimeout(()=>{
            if (error && error.status && (error.status === 401 || error.status === 403)) {
                navigate("/login")
            }
        },100)
        if (isSuccessCreate) showSuccess(CATEGORY_SUCCESSFULLY_ADDED)
        if (isErrorCreate) showError((errorCreate as any)?.data?.message)
    }, [error, isSuccessCreate, isErrorCreate, errorCreate])

    const onOpenHandler = () => setVisible(true)

    const onCloseHandler = () => setVisible(false)

    const onFinishHandler = async (dto: CreateCategoryDTO) => {
        setLoadingButton(true)
        createCategory(dto)
        setVisible(false)
        setLoadingButton(false)
    }

    return (
        <Layout>
            <Button style={{margin: '16px 0'}} onClick={onOpenHandler}>+ Добавить категорию</Button>
            <Modal
                title="Добавить категорию"
                open={visible}
                onCancel={onCloseHandler}
                okText="Создать"
                destroyOnClose
                okButtonProps={{
                    disabled: loadingButton,
                    form: "category-create-form",
                    htmlType: "submit"
                }}
            >
                <Divider/>
                <CategoryCreateForm category={undefined} onFinish={onFinishHandler}/>
            </Modal>
            <Table
                bordered
                rowKey={row => row.id}
                onChange={onChangeTable}
                loading={loading}
                columns={columns}
                dataSource={categories}
                pagination={{pageSize: filter.size, current: filter.page, total: total}}
            />
        </Layout>
    )

}

export default Category;