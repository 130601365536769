import React, {useEffect, useState} from 'react';
import {Button, Divider, Modal, Table} from 'antd';
import {useColumns} from "./UseColumns";
import ProductCreateForm from "./ProductCreateForm";
import {useGetProducts} from "../../hooks/UseGetProducts";
import {useCreateProductMutation} from "../../store/api/ProductApi";
import {CreateProductDTO} from "../../types/product/CreateProductDTO";
import {showError, showSuccess} from "../../utils/Extensions";
import Layout from "../../layouts/Layout";
import {PRODUCT_SUCCESSFULLY_ADDED} from "../../utils/Constants";
import {useNavigate} from "react-router-dom";

const Product: React.FC = () => {

    const {products, onChangeTable, loading, filter, total, error} = useGetProducts()
    const columns = useColumns()
    const navigate = useNavigate()
    const [visible, setVisible] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [createProduct, {
        isSuccess: isSuccessCreate,
        isError: isErrorCreate,
        error: errorCreate
    }] = useCreateProductMutation()

    useEffect(() => {
        if (error && error.status && (error.status === 401 || error.status === 403)) {
            navigate("/login")
        }
        if (isSuccessCreate) showSuccess(PRODUCT_SUCCESSFULLY_ADDED)
        if (isErrorCreate) showError((errorCreate as any)?.data?.message)
    }, [error, isSuccessCreate, isErrorCreate, errorCreate])

    const onOpenHandler = () => setVisible(true)

    const onCloseHandler = () => setVisible(false)

    const onFinishHandler = async (dto: CreateProductDTO) => {
        setLoadingButton(true)
        createProduct(dto)
        setVisible(false)
        setLoadingButton(false)
    }

    return (
        <Layout>
            <Button style={{margin: '16px 0'}} onClick={onOpenHandler}>+ Добавить продукт</Button>
            <Modal
                title="Добавить продукт"
                open={visible}
                onCancel={onCloseHandler}
                okText="Создать"
                destroyOnClose
                okButtonProps={{
                    disabled: loadingButton,
                    form: "product-create-form",
                    htmlType: "submit"
                }}
            >
                <Divider/>
                <ProductCreateForm product={undefined} onFinish={onFinishHandler}/>
            </Modal>
            <Table
                bordered
                rowKey={row => row.id}
                onChange={onChangeTable}
                loading={loading}
                columns={columns}
                dataSource={products}
                pagination={{pageSize: filter.size, current: filter.page, total: total}}
            />
        </Layout>
    )
}

export default Product;