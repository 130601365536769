import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {ResponsePageable} from "../../types/response/ResponsePageable";
import {Response} from "../../types/response/Response";
import {apiQuery} from "../../utils/AppConfig";
import {GetProductDTO} from "../../types/product/GetProductDTO";
import {CreateProductDTO} from "../../types/product/CreateProductDTO";

export const productApi = createApi({
    reducerPath: 'productUrl',
    baseQuery: apiQuery,
    tagTypes: ['productUrl'],
    endpoints: builder => ({
        getProducts: builder.query<Response<ResponsePageable<GetProductDTO>>, {
            page: number
            size: number
        }>({
            query: (dto) => ({
                url: `/products?page=${dto.page}&size=${dto.size}`,
                method: 'GET'
            }),
            providesTags: ['productUrl']
        }),
        createProduct: builder.mutation<void, Partial<CreateProductDTO>>({
            query: (dto) => ({
                url: '/products',
                method: 'POST',
                body: dto
            }),
            invalidatesTags: ['productUrl']
        }),
        updateProduct: builder.mutation<void, Partial<{ id: number, dto: CreateProductDTO }>>({
            query: ({id, dto}) => ({
                url: `/products/${id}`,
                method: 'PATCH',
                body: dto
            }),
            invalidatesTags: ['productUrl']
        }),
        deleteProduct: builder.mutation<void, number>({
            query: (id) => ({
                url: `/products/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['productUrl']
        }),
    })
})

export const {
    useGetProductsQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation
} = productApi