import {Filter} from "../types/utils/Filter";
import {DEFAULT_PAGE, DEFAULT_SIZE, PAGE, SIZE} from "./Constants";

type SelectParamsFromQuery = (query: URLSearchParams) => Filter

export const selectParamsFromQuery: SelectParamsFromQuery = (query) => {
    const data: Filter = {
        page: Number(query.get(PAGE) || DEFAULT_PAGE),
        size: Number(query.get(SIZE) || DEFAULT_SIZE)
    }
    return data
}

type SelectQueryFromParams = (pagination: { current?: number, pageSize?: number }, search?: string) => string

export const selectQueryFromParams: SelectQueryFromParams = (pagination, search) => {
    const query = new URLSearchParams(search)

    pagination.current && query.set(PAGE, pagination.current.toString())
    pagination.pageSize && query.set(SIZE, pagination.pageSize.toString())
    return query.toString()
}