import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from "./store/Store"
import {ConfigProvider} from "antd";
import ru_RU from 'antd/locale/ru_RU';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <ConfigProvider locale={ru_RU}>
            <App/>
        </ConfigProvider>
    </Provider>
);
reportWebVitals();
