import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {AUTHORIZATION, BEARER, TOKEN} from "./Constants";

// Настойки для API
export const apiQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem(TOKEN);
        headers.set(AUTHORIZATION, `${BEARER} ${token}`)
        return headers
    }
})