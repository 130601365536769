import type {ColumnsType} from "antd/es/table"
import {Image} from 'antd';
import ProductAction from "./ProductAction";
import {GetProductDTO} from "../../types/product/GetProductDTO";

type UseColumnsType = () => ColumnsType<GetProductDTO>

export const useColumns: UseColumnsType = () => {

    const columns: ColumnsType<GetProductDTO> = [
        {
            title: "ИД",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Название продукта на узбекском",
            dataIndex: "name_uz",
            key: "name_uz",
            render: (_, {name}) => name.uz
        },
        {
            title: "Название продукта на русском",
            dataIndex: "name_ru",
            key: "name_ru",
            render: (_, {name}) => name.ru
        },
        {
            title: "Категория",
            dataIndex: "category",
            key: "category_name",
            render: (_, {category}) => category.name.ru
        },
        {
            title: "Фото",
            dataIndex: "picture_url",
            key: "picture_url",
            render: (_, {picture_url}) => <>
                <Image width={30} src={`${process.env.REACT_APP_BASE_URL}${picture_url}`}></Image>
            </>
        },
        {
            title: "Цена",
            dataIndex: "price",
            key: "price"
        },
        {
            title: "Количество лояльности",
            dataIndex: "loyalty_count",
            key: "loyalty_count"
        },
        {
            title: "Действие",
            dataIndex: "action",
            key: "action",
            align: "center",
            render: (_, record) => <ProductAction product={record}/>
        }
    ]

    return columns
}