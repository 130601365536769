export const AUTHORIZATION = "Authorization"
export const BEARER = "Bearer"
export const TOKEN = "token"
export const ERROR = "Ошибка"
export const NOTIFICATION_DURATION = 5

// PAGINATION
export const PAGE = "page"
export const SIZE = "size"
export const DEFAULT_PAGE = 1
export const DEFAULT_SIZE = 20

// LOGIN
export const WRONG_USERNAME_OR_PASSWORD = "Имя пользователя и/или пароль неверны"

// CATEGORY
export const CATEGORY_SUCCESSFULLY_ADDED = "Категория успешно создана!"
export const CATEGORY_SUCCESSFULLY_UPDATED = "Категория успешно изменена!"
export const CATEGORY_SUCCESSFULLY_DELETED = "Категория успешно удалена!"

// PRODUCT
export const PRODUCT_SUCCESSFULLY_ADDED = "Продукт успешно создан!"
export const PRODUCT_SUCCESSFULLY_UPDATED = "Продукт успешно изменен!"
export const PRODUCT_SUCCESSFULLY_DELETED = "Продукт успешно удален!"