import React, {useState} from 'react';
import {AppstoreOutlined, ShoppingCartOutlined} from '@ant-design/icons';
import type {MenuProps} from 'antd';
import {Layout as AntdLayout, Menu, theme} from 'antd';
import {Link, useLocation} from "react-router-dom";

const {Header, Content, Sider} = AntdLayout;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    {label: <Link to={'/category'}>Категории</Link>, key: '/category', icon: <AppstoreOutlined />},
    {label: <Link to={'/product'}>Продукты</Link>, key: '/product', icon: <ShoppingCartOutlined />}
];

interface LayoutProps {
    children?: React.ReactNode | React.ReactNode[];
}

const Layout: React.FC<LayoutProps> = ({children}) => {
    const location = useLocation()
    const [collapsed, setCollapsed] = useState(false);
    const {token: {colorBgContainer}} = theme.useToken();

    return (
        <AntdLayout style={{minHeight: '100vh'}}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <Menu theme={"dark"} defaultSelectedKeys={[location.pathname]} items={items}/>
            </Sider>
            <AntdLayout>
                <Header style={{padding: 0, background: colorBgContainer, textAlign: 'center'}}> Rulls </Header>
                <Content style={{margin: '16px 16px'}}>
                    {children}
                </Content>
            </AntdLayout>
        </AntdLayout>
    );
};

export default Layout;