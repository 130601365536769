import React, {useEffect} from 'react';
import {Button, Col, Form, Input, Row} from 'antd';
import {useAuthMutation} from "../../store/api/AuthApi";
import {LoginDTO} from "../../types/auth/LoginDTO";
import {useNavigate} from "react-router-dom";
import {TOKEN, WRONG_USERNAME_OR_PASSWORD} from "../../utils/Constants";
import {showError} from "../../utils/Extensions";

const Login: React.FC = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [auth, {isSuccess, isError, data}] = useAuthMutation()

    const onFinishHandler = (values: any) => {
        const loginDTO: LoginDTO = {
            username: values.username,
            password: values.password
        }
        auth(loginDTO)
    };

    useEffect(() => {
        if (isSuccess && data?.data) {
            localStorage.setItem(TOKEN, data.data)
            navigate("/category")
        }
        if (isError) {
            showError(WRONG_USERNAME_OR_PASSWORD)
        }
    }, [isSuccess, isError, data])

    useEffect(() => {
        if (localStorage.getItem(TOKEN)) {
            navigate("/category")
        }
    }, []);

    return (
        <Row justify={"center"} align={"middle"} style={{minHeight: '100vh'}}>
            <Col span={6}>
                <Form
                    form={form} id={'login-form'} layout="vertical"
                    style={{maxWidth: 600}}
                    onFinish={onFinishHandler}
                >
                    <Form.Item
                        label="Имя пользователя"
                        name="username"
                        rules={[{required: true, message: 'Введите имя пользователя'}]}
                    >
                        <Input placeholder={'Введите имя пользователя'}/>
                    </Form.Item>
                    <Form.Item
                        label="Пароль"
                        name="password"
                        rules={[{required: true, message: 'Введите пароль'}]}
                    >
                        <Input.Password placeholder={'Введите пароль'}/>
                    </Form.Item>
                    <Form.Item>
                        <Button style={{width: '100%'}} type={'primary'} htmlType="submit">
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    )
}

export default Login;
